import { combineReducers } from 'redux';
import ConfirmationReducer from './confirmation/ConfirmationReducer';
import CustomizerReducer from './customizer/CustomizerReducer';
import PaymentReducer from './payment/PaymentReducer';

const RootReducers = combineReducers({
  ConfirmationReducer,
  CustomizerReducer,
  PaymentReducer,
});

export default RootReducers;
