import { CONFIRMATION, CLEAN } from '../constants';

const INIT_STATE = null;

const ConfirmationReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CONFIRMATION:
      return action.payload;
    case CLEAN:
      return null;
    default:
      return state;
  }
};

export default ConfirmationReducer;
