import { 
  CARD, CUSTOMER_INFORMATION, AMOUNT, REFERENCE, 
  FETCH_DATA_REQUEST, FETCH_DATA_SUCCESS, FETCH_DATA_FAILURE,
  FETCH_REQUEST_DONE, PAYMENT 
} from '../constants';

const INIT_STATE = {
  loading: false,
  title: "",
  authorization: null,
  amount: 0.00,
  reference: "",
  currency: "484",
  customerInformation: {
      firstName: "",
      lastName: "",
      middleName: "",
      email: "",
      phone1: "",
      city: "Mexico",
      address1: "",
      postalCode: "11950",
      state: "Mexico",
      country: "MX",
      ip: "0.0.0.0"
  },
  cardData: {
      cardNumber: "",
      cvv: "",
      cardholderName: "",
      expirationYear: "",
      expirationMonth: ""
  },
  error: null
};

const PaymentReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CARD:
      return {
        ...state,
        cardData: action.payload,
      };
    case CUSTOMER_INFORMATION:
      return {
        ...state,
        customerInformation: action.payload,
      };
    case AMOUNT:
      return {
        ...state,
        amount: action.payload,
      };
    case REFERENCE:
      return {
        ...state,
        reference: action.payload,
      };
    case FETCH_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_REQUEST_DONE:
      return {
        ...state,
        loading: false,
        error: null
      };
    case FETCH_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        amount: action.payload.amount,
        reference: `${action.payload.external_reference}-${action.payload.sku}`,
        title: action.payload.title,
        image: action.payload.image,
        authorization: action.payload.authorization
      };
    case FETCH_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: { message: action.payload }
      };
    case PAYMENT:
      console.log('state ', state);
      return state;

    default:
      return state;
  }
};

export default PaymentReducer;
