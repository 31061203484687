// Layout and colors CONSTANTS
export const LIGHT_THEME = 'LIGHT_THEME';
export const DARK_THEME = 'DARK_THEME';
export const THEME_COLOR = 'THEME_COLOR';
export const NAVBAR_BG = 'NAVBAR_BG';
export const SIDEBAR_BG = 'SIDEBAR_BG';
export const DIRECTION = 'DIRECTION';
export const ETOMIN_THEME = 'ETOMIN_THEME';
export const BLUE_THEME = 'BLUE_THEME';
export const GREEN_THEME = 'GREEN_THEME';
export const RED_THEME = 'RED_THEME';
export const BLACK_THEME = 'BLACK_THEME';
export const PURPLE_THEME = 'PURPLE_THEME';
export const INDIGO_THEME = 'INDIGO_THEME';
export const ORANGE_THEME = 'ORANGE_THEME';

export const CARD = 'CARD';
export const CUSTOMER_INFORMATION = 'CUSTOMER_INFORMATION';
export const AMOUNT = 'AMOUNT';
export const REFERENCE = 'REFERENCE';
export const FETCH_DATA_REQUEST = 'FETCH_DATA_REQUEST';
export const FETCH_DATA_SUCCESS = 'FETCH_DATA_SUCCESS';
export const FETCH_DATA_FAILURE = 'FETCH_DATA_FAILURE';
export const FETCH_REQUEST_DONE = 'FETCH_REQUEST_DONE';
export const PAYMENT = 'PAYMENT';

export const CONFIRMATION = 'CONFIRMATION';
export const CLEAN = 'CLEAN';
