import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';

/* ***Layouts**** */
const FullLayout = lazy(() => import('../layouts/full-layout/FullLayout'));
const BlankLayout = lazy(() => import('../layouts/blank-layout/BlankLayout'));
/* ***End Layouts**** */

const Error = lazy(() => import('../views/authentication/Error'));

/* ****Pages***** */
// const Dashboard = lazy(() => import('../views/dashboard/Dashboard'));
const Checkout = lazy(() => import('../views/checkout'));
const Confirmation = lazy(() => import('../views/checkout/confirmation'));
/* ****Routes***** */

const Router = [
  {
    path: '/',
    element: <FullLayout />,
    children: [
      { path: '/', element: <Navigate to="/checkout/:userId/item/:item" /> },
      // { path: '/dashboards/starter', exact: true, element: <Dashboard /> },
      { path: '/checkout/:userId/item/:item', exact: true, element: <Checkout /> },
      { path: '/confirmation', exact: true, element: <Confirmation /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
  {
    path: '/auth',
    element: <BlankLayout />,
    children: [
      { path: '404', element: <Error /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
];

export default Router;
